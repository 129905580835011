/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import $ from 'jquery';
import Axios from 'axios';
import Blank from '../images/empty.jpg';
import Loading from '../images/loading.gif';



import "./formrelation.css";

class OpeenseesCircFRP extends React.Component{
    //javascript
    state = {
      val1: 5,
      val2: 1,
      val3: 15,
      val4: 12,
      val5: 2,
      val6: 0.05,
      val7: 0.007,
      val8: -20000,
      val9: -0.002,
      val10: -0.0035,
      val11: 30000000,
      val12: 2200,
      val13: 0.001,
      val14: 3500000,
      val15: 220000000,
      val16: 0.014,
      val17: 500000,
      val18: 200000000,
      val19: 0.05,
      val20: 18,
      val21: 30,
      val22: 10,
      val23: 0.20,
      val24: 550,
      val25: 0.5,
      ColumnGenerator: 'CircularFRP',
      src: Blank,
      errorclass: '',
    }
    handleInputChange = event => {
      const target = event.target
      const value = target.value
      const name = target.name
      const value2 = value.replace(",",".");
      
      this.setState({
        [name]: value2,
      })
    }
    
    handleSubmit = async e => {
      e.preventDefault();
      
      this.setState({
        src: Loading,
      })
      
      //development graph image
      //let path = 'https://www.mathsisfun.com/data/images/line-graph-example.svg';

      // this.setState({
      //   src: path,
      // })

      
      await Axios.get('https://thebridgedatabase.pythonanywhere.com/CantileverBeam?ColumnGenerator='+this.state.ColumnGenerator+'&val1='+this.state.val1+'&val2='+this.state.val3+'&val3='+this.state.val3+'&val4='+this.state.val4+'&val5='+this.state.val5+'&val6='+this.state.val6+'&val7='+this.state.val7+'&val8='+this.state.val8+'&val9='+this.state.val9+'&val10='+this.state.val10+'&val11='+this.state.val11+'&val12='+this.state.val12+'&val13='+this.state.val13+'&val14='+this.state.val14+'&val15='+this.state.val15+'&val16='+this.state.val16+'&val17='+this.state.val17+'&val18='+this.state.val18+'&val19='+this.state.val19+'&val20='+this.state.val20+'&val21='+this.state.val21+'&val22='+this.state.val22+'&val23='+this.state.val23+'&val24='+this.state.val24+'&val25='+this.state.val25,{
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "mode":"cors",
        }
      })
        .then(result => {
            
            let response = result.data;
            this.setState({
              src: Blank,
            })
            // let imageurl = result.data;
            // this.setState({
            //   src: imageurl,
            // })
            console.log('Success1') // Actually see what is returned.
           
        })
        .catch(err => {
          this.setState({
            src: Blank,
            errorclass: 'errorfetch',
          })
          
            
        })

        await Axios.get('https://thebridgedatabase.pythonanywhere.com/PlotPushOver?ColumnGenerator='+this.state.ColumnGenerator,{
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "mode":"cors",
        }
      })
        .then(result => {
            
            
            let imageurl = result.data;
            this.setState({
              src: imageurl,
            })
            console.log('Success2') // Actually see what is returned.
           
        })
        .catch(err => {
          this.setState({
            src: Blank,
            errorclass: 'errorfetch',
          })
          
            
        })
        
      
    };
    
  
    
    render(){
        
        
      return(
          <>
        <form onSubmit={this.handleSubmit}>
          <div className="group-fields">
            <h2>Element Properties</h2>
            <label>
            H(m):
            <input
                type="text"
                name="val1"
                value={this.state.val1}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Height of Pier</div></div>
            </label>

            <label>
            Num. of Elements:
            <input
                type="text"
                name="val2"
                value={this.state.val2}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Number of elements - Mesh</div></div>
            </label>

            <label>
            Fibers Perimeter:
            <input
                type="text"
                name="val3"
                value={this.state.val3}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Number of Fibers along Perimeter</div></div>
            </label>


            <label>
            Fibers Radius:
            <input
                type="text"
                name="val4"
                value={this.state.val4}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Number of Fibers along Radius</div></div>
            </label>
          </div>
          <div className="group-fields">
           <h2>Section Properties</h2>
           <h3>Geometry</h3>
           <label>
            D<sub>core</sub> (m):
            <input
                type="text"
                name="val5"
                value={this.state.val5}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of core section</div></div>
            </label>
            <label>
            c<sub>core</sub> (m):
            <input
                type="text"
                name="val6"
                value={this.state.val6}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Cover of core section</div></div>
            </label>
            <label>
            t<sub>FRP</sub> (mm):
            <input
                type="text"
                name="val7"
                value={this.state.val7}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Thickness of FRP</div></div>
            </label>
            <h3>Materials</h3>
            <h4>1. Concrete of core section (<a href="https://opensees.berkeley.edu/wiki/index.php/Concrete04_Material_--_Popovics_Concrete_Material" target="_blank">OpenSees Material 04</a>)*</h4>
           <label>
           fco<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val8"
                value={this.state.val8}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete compressive strength (unconfined) of core section</div></div>
            </label>
            <label>
            ec<sub>, core</sub>:
            <input
                type="text"
                name="val9"
                value={this.state.val9}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete strain at maximum strength of core section</div></div>
            </label>
            <label>
            ecu<sub>, core</sub>:
            <input
                type="text"
                name="val10"
                value={this.state.val10}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Concrete strain at crushing strength of core section</div></div>
            </label>
            <label>
            Ec<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val11"
                value={this.state.val11}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Initial Stiffness of core section (see Opensees Mat04)</div></div>
            </label>
            <label>
            fct<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val12"
                value={this.state.val12}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Maximum tensile strength of concrete of core section</div></div>
            </label>
            <label>
            et<sub>, core</sub>:
            <input
                type="text"
                name="val13"
                value={this.state.val13}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Ultimate tensile strain of concrete of core section</div></div>
            </label>
            <div className="reference-star">FRP-Confined concrete properties estimated according to Spoelstra &amp; Monti (1999)<span>Spoelstra, B. M. R., &amp; Monti, G. (1999). FRP-Confined Concrete Model. Journal of Composites for Construction, 3(3), 143–150</span></div>
            <h4>2. FRP</h4>
           <label>
           f<sub>FRP</sub> (kPa):
            <input
                type="text"
                name="val14"
                value={this.state.val14}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Tensile strength of FRP</div></div>
            </label>
            <label>
            E<sub>FRP</sub>:
            <input
                type="text"
                name="val15"
                value={this.state.val15}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Modulus of Elasticity of FRP</div></div>
            </label>
            <label>
            ε<sub>cu, FRP</sub>:
            <input
                type="text"
                name="val16"
                value={this.state.val16}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Maximum strain of FRP</div></div>
            </label>
            <h4>3. Reinforcement steel of core section (<a href="https://opensees.berkeley.edu/wiki/index.php/Steel02_Material_--_Giuffr%C3%A9-Menegotto-Pinto_Model_with_Isotropic_Strain_Hardening" target="_blank">OpenSees Material 02</a>)</h4>
            <label>
            fy<sub>, core</sub> (kPa):
            <input
                type="text"
                name="val17"
                value={this.state.val17}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Yield strength of reinforcement steel of core section</div></div>
            </label>
            <label>
            E<sub>0, core</sub> (kPa):
            <input
                type="text"
                name="val18"
                value={this.state.val18}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Initial Elastic Tangent of core section (see Opensees Mat02)</div></div>
            </label>
            <label>
            b<sub>, core</sub>:
            <input
                type="text"
                name="val19"
                value={this.state.val19}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Strain - Hardening ratio (ratio between post-yield tangent and initial elastic tangent) of core section (see Opensees Mat02)</div></div>
            </label>
            <h3>Reinforcement (core)</h3>
            <label>
             Φ<sub>L, core</sub> (mm):
            <input
                type="text"
                name="val20"
                value={this.state.val20}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of Longitudinal Reinforcement  of core section</div></div>
            </label>
            <label>
            Num. of Long. Reinf. Bars of core:
            <input
                type="text"
                name="val21"
                value={this.state.val21}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Total number of longitudinal reinforcement bars of core section</div></div>
            </label>
            <label>
            Φ<sub>w, core</sub> (mm)::
            <input
                type="text"
                name="val22"
                value={this.state.val22}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Diameter of Transverse Reinforcement of core section</div></div>
            </label>
            <label>
            s<sub>core</sub> (m):
            <input
                type="text"
                name="val23"
                value={this.state.val23}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Distance of transverse reinforcement of core section</div></div>
            </label>
          </div>
          <div className="group-fields">
            <h2>Analysis Properties &amp; Loading</h2>
            <label>
            N (kN):
            <input
                type="text"
                name="val24"
                value={this.state.val24}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Vertical Load (G+0.3Q)</div></div>
            </label>
            <label>
            d<sub>target</sub> (m):
            <input
                type="text"
                name="val25"
                value={this.state.val25}
                onChange={this.handleInputChange}
            />
            <div className="info-trigger"><span >i</span><div className="info-message">Target Displacement for Pushover Analysis</div></div>
            </label>
          </div>
          <button type="submit">Calculate</button>
            
      </form>
      <p className={`errormessage ${this.state.errorclass}`}>The system encountered a problem. Please <a href="/contact">contact us</a> to report this error.</p>
      <img className="opensees-image" src={this.state.src} />
    </>
      )
    }
}
export default OpeenseesCircFRP
